<template>
	<div>
		<BackButton :route="{ name: 'content-pages', label: 'Pages' }" />

		<ContentBuilder ref="builder" :url-prefix="urlPrefix" page-type="Page" @updated="updated" />
	</div>
</template>

<script>
import { computed, provide, inject, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default {
	components: {},
	setup() {
		const api = inject('api')
		const toast = useToast()

		return {
			api,
			toast,
		}
	},
	data() {
		return {
			urlPrefix: '/',
			id: this.$route.params.id,
		}
	},
	mounted() {},
	methods: {
		updated(slug) {
			this.$router.push({ name: 'content-pages-id', params: { id: slug } })
		},
	},
}
</script>
